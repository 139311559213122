import http from '@/middleware'

const thingApi = {
    /**
     * Recurso para obtener el árbol de grupos -> equipos para los filtros
     * @returns
     */
    async getNested(thingType = null) {
        const path =
        thingType != null
            ? `/api/thing/all-nested?thingType=${thingType}&forProducts=true`
            : "/api/thing/all-nested/?forProducts=true";
        const response = await http.get(path);
        return response;
    }
}

export default thingApi
