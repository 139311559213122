import thingApi from "@/api/thing.api";
import filterModel from "@/components/filter/model";
import { clone } from "ramda";

export const filterService = (function () {
    'use strict'

    /**
     * Obtener el árbol de empresa -> grupo -> equipo para el selector
     * @param {*} thingType
     */
    const getEnterpriseGroupEquipmentTreeSelector = async (thingType = null) => {
        try {
            const response = await thingApi.getNested(thingType);
            //const lang = localStorage.getItem('locale');
            const data = response.data.map(groupType => {
                return {...groupType, name: groupType.name}
            });
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Obtener el modelo para el filtro
     * @returns
     */
    const getFilterModel = () => {
        const model = clone(filterModel)
        return model
    }

    return {
        getEnterpriseGroupEquipmentTreeSelector,
        getFilterModel
    }

})()
