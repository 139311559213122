import { render, staticRenderFns } from "./GeneralSectionComponent.vue?vue&type=template&id=6018140a&scoped=true"
import script from "./GeneralSectionComponent.js?vue&type=script&lang=js&external"
export * from "./GeneralSectionComponent.js?vue&type=script&lang=js&external"
import style1 from "./GeneralSectionComponent.vue?vue&type=style&index=1&id=6018140a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6018140a",
  null
  
)

export default component.exports