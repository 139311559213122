export default {
  name: 'ListTreeViewComponent',
  /**
   * MODELO
   *
   * Arreglo con los datos para mostrar en la lista.
   *
   * PROPIEDADES
   *
   * itemKey: nombre de la propiedad que representa el id de los objetos de la lista.
   * itemText: nombre de la propiedad que representa el texto para mostrar los objetos.
   * itemChildren: nombre de la propiedad que contiene los hijos de cada objeto.
   *
   * EVENTOS
   * change: evento que se lanza cuando se modifica el modelo.
   */
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    itemChildren: {
      type: String,
      required: true
    },
    listId: {
      type: String,
      required: false,
      default: () => { return 'listTreeview' }
    }
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  mounted () {
  },
  methods: {
    /**
     * Para convertir los espacios en blanco a guiones de una cadena de caracteres
     * @param {*} str
     */
    convertWhiteSpacesToDash (str) {
      return str != null && str !== '' ? str.replace(/\s+/g, '_') : ''
    }
  },
  watch: {
    model: function () {
    }
  }
}
