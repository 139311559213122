import { YieldUnits } from '@colven/common-domain-lib/lib';
import i18n from "@/i18n";
/**
 * SECCIÓN DE CONFIGURACIÓN
 *
 * MODELO: {
 *  selectedYieldUnit: string
 * }
 * PROPS:
 * ---showsFuelData: booleano que indica si mostrar o no el tab Configuración, en base a si el reporte muestra
 *  información de combustible o no. Opcional, puesto como TRUE por defecto.
 */
export default {
    name: "ConfigurationsSectionComponent",
    model: {
        prop: "model",
        event: "change"
    },
    data: () => ({
        showConfigurationsSection: false,
        showYieldUnit: true,
        yieldUnits: [
        {
            id: YieldUnits.LITERS_PER_100KM,
            text: i18n.t("filters.tabConfigurations.selector.litersPer100Km"),
        },
        {
            id: YieldUnits.KM_PER_LITER,
            text: i18n.t("filters.tabConfigurations.selector.kmPerLiter"),
        },
        {
            id: YieldUnits.MILES_PER_GALLON,
            text: i18n.t("filters.tabConfigurations.selector.milesPerGallon"),
        },
        ],
    }),
    props: {
        model: {
        type: Object,
        required: true,
        },
        showsFuelData: {
        type: Boolean,
        required: false,
        default: true,
        },
    },
    methods: {
        arrowIcon(show) {
        if (show) {
            return "arrow_drop_up";
        } else {
            return "arrow_drop_down";
        }
        },
    },
};
