import SelectorDialog from "@/components/commons/dialog/SelectorDialog.vue";
import ListTreeViewComponent from "@/components/commons/list/tree-view/ListTreeViewComponent.vue";
import { filterTypes } from "../../../../constants/constants";
import deepcopy from "deepcopy";
import i18n from "@/i18n";
import { filterService } from "@/business/filterService";
import { getIds } from "@/tools/functions";
/**
 * SECCIÓN GENERAL
 *
 * MODELO: {
 *  selectedThings: [],
 *  serviceType: [],
 *  maxQuantity: string
 * }
 * 
 * PROPS:
 * ---filterType: constante que indica el tipo de filtro, si es por EQUIPOS o CHOFERES. Se setearía según alguna de las constantes filterTypes. No está implementado full ya que por ahora solo se contempla que sea de EQUIPOS. 
 */
export default {
  name: "GeneralSectionComponent",
  model: {
    prop: "model",
    event: "change"
  },
  components: {
    SelectorDialog,
    ListTreeViewComponent,
  },
  data: () => ({
    selectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t("filters.title"),
      singleSelect: false,
      selectAll: false,
      saveDisabled: false,
    },
    currentFilter: undefined,
    showGroupEquipmentOrDriverFilter: false,
    showMaxQuantityFilter: false,
    showGeneralSection: false,
    equipmentsFilter: {
      id: "equipmentsFilter",
      name: i18n.t("filters.entityName.things"),
      data: [],
      selectedData: [],
      disabled: false,
      selectAction: undefined,
      singleSelect: false,
    },
    rules: {
      invalidValue: (value) => !value || value > 0 || `${i18n.t('filters.error.invalidValue')}`,
      limitReached: (value, limit) => !value || value <= Number(limit) || `${i18n.t('filters.error.maxLimitReached', limit)}`,
    }
  }),
  props: {
    model: {
      type: Object,
      required: true,
    },
    maxQuantityLimit: {
      type: Number,
      required: true
    },
    filterType: {
      type: String,
      required: false,
      default: filterTypes.EQUIPMENTS,
    },
    //prop para que solo aparezca el selector de equipos y deje seleccionar un único equipo
    selectThingOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
  },
  async mounted() {
    // cargar datos para el selector de grupos -> equipos
    this.equipmentsFilter.data = await filterService.getEnterpriseGroupEquipmentTreeSelector("LOGISTIC_TRUCK");

    //inicialización de la data seleccionada para los selectores
    this.equipmentsFilter.selectedData = this.model.selectedThings;
    if (this.selectThingOnly) {
      this.model.maxQuantity = 1;
      this.equipmentsFilter.singleSelect = true;
      this.equipmentsFilter.disableBranch = true;
    }
  },
  methods: {
    arrowIcon(show) {
      if (show) {
        return "arrow_drop_up";
      } else {
        return "arrow_drop_down";
      }
    },
    getIds(data, singleSelect) {
      if (singleSelect) {
        return data.length === 1 ? data[0].id : null;
      } else {
        const result = [];
        getIds(data, result);
        return result;
      }
    },
    /**
     *
     */
    saveDialogAction(data) {
      this.currentFilter.selectorDialogItems = deepcopy(data);
      if (data) {
        const selectedItems = [];
        if (this.currentFilter.singleSelect && data) {
          selectedItems.push(data);
        }
        const itemsCopy = deepcopy(this.currentFilter.data);
        const filteredData = itemsCopy.filter(function filterFunction(item) {
          if (data.includes(item.id)) return true;
          if (item.children && item.children.length > 0) {
            return (item.children = item.children.filter(filterFunction))
              .length;
          }
        });
        selectedItems.cleanAndUpdate(filteredData);
        this.currentFilter.selectedData.cleanAndUpdate(selectedItems);
        if (this.currentFilter.id === "equipmentsFilter") {
          this.model.selectedThings.cleanAndUpdate(selectedItems)
        }
      } else {
        this.currentFilter.selectedData.clean();
        if (this.currentFilter.id === "equipmentsFilter") {
          this.model.selectedThings.clean()
        }
      }
      if (this.currentFilter.selectAction) this.currentFilter.selectAction();
      this.selectorDialogModel.show = false;
    },

    /**
     * Se carga el modelo de datos del diálogo de selección
     * @param {*} filterData
     */
    selectEntities(filterData) {
      this.currentFilter = filterData;
      this.selectorDialogModel.data = this.currentFilter.data;
      /*
        Si no hay datos en la prop selectorDialogItems, lo cual significaría la primer apertura del diálogo
        para ese filtro, se verifica que no haya datos en la prop selectedData (si se configuraron unos datos
        seleccionados por defecto), o sino, se setea en null.
      */
      this.selectorDialogModel.selected =
        filterData.selectedData.length > 0 &&
        this.currentFilter.selectorDialogItems
          ? this.currentFilter.selectorDialogItems
          : filterData.selectedData.length > 0
          ? this.getIds(filterData.selectedData, filterData.singleSelect)
          : null;
      this.selectorDialogModel.title = this.$t(
        "filters.selectionDialog.title",
        { entityName: filterData.name }
      );
      this.selectorDialogModel.singleSelect = filterData.singleSelect;
      this.selectorDialogModel.selectAll = false;
      this.selectorDialogModel.show = true;
      this.selectorDialogModel.disableBranch = filterData.disableBranch || false;
    },

    cancelDialogAction() {
      this.selectorDialogModel.show = false;
    },
  },
};
