import { SortOptions } from '@colven/common-domain-lib/lib';
/**
 * SECCIÓN DE ORDENAMIENTO
 *
 * MODELO: {
 *  selectedSortOption: string,
 * }
 */
export default {
    name: "OrderSectionComponent",
    model: {
        prop: "model",
        event: "change"
    },
    props: {
        model: {
            type: Object,
            required: true,
        },
        worstBestPropertyName: {
            type: String,
            required: false,
            default: null
        }
    },
    data: () => ({
        showOrderSection: false,
        showSortOptions: true
    }),
    computed: {
        orderSortOptions () {
            if (this.worstBestPropertyName) {
                return [
                    {
                        id: SortOptions.ALPHABETIC_ASC_NAME,
                        text: this.$t("filters.tabOrder.selector.alphabeticAscName")
                    },
                    {
                        id: SortOptions.ALPHABETIC_DESC_NAME,
                        text: this.$t("filters.tabOrder.selector.alphabeticDescName")
                    },
                    {
                        id: SortOptions.BEST_FIRST,
                        text: this.$t("filters.tabOrder.selector.bestFirst", {propName: this.worstBestPropertyName})
                    },
                    {
                        id: SortOptions.WORST_FIRST,
                        text: this.$t("filters.tabOrder.selector.worstFirst", {propName: this.worstBestPropertyName})
                    },
                    {
                        id: SortOptions.NEWEST_DATA,
                        text: this.$t("filters.tabOrder.selector.newestData")
                    },
                    {
                        id: SortOptions.OLDEST_DATA,
                        text: this.$t("filters.tabOrder.selector.oldestData")
                    }
                ]
            } else {
                return [
                    {
                        id: SortOptions.ALPHABETIC_ASC_NAME,
                        text: this.$t("filters.tabOrder.selector.alphabeticAscName")
                    },
                    {
                        id: SortOptions.ALPHABETIC_DESC_NAME,
                        text: this.$t("filters.tabOrder.selector.alphabeticDescName")
                    },
                    {
                        id: SortOptions.NEWEST_DATA,
                        text: this.$t("filters.tabOrder.selector.newestData")
                    },
                    {
                        id: SortOptions.OLDEST_DATA,
                        text: this.$t("filters.tabOrder.selector.oldestData")
                    }
                ]
            }
        }
    },
    mounted() {
    },
    methods: {
        arrowIcon(show) {
            if (show) {
                return "arrow_drop_up";
            } else {
                return "arrow_drop_down";
            }
        }
    },
};