import i18n from "@/i18n";
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib';
import { validateCustomDateRange, validateCustomDateAndTimeRange } from '@/tools/functions';

/**
 * SECCIÓN DE FECHA Y HORA
 *
 * MODELO: {
 *  selectedDateAndTimeRange: string
 * }
 */
export default {
    name: "DateSectionComponent",
    model: {
        prop: 'model',
        event: 'change'
    },
    data: () => ({
        showDateSection: false,
        selectedDateAndTimeRangeIsCUSTOM: false,
        dateAndTimeRanges: [
        ],
        dateAndTimeRangesWithoutCustom: [
        ],
        dateAndTimeCustomRangeTypes: [
            {
                text: i18n.t("filters.tabDate.dateAndTime.customTypes.dateAndTime"),
                value: CustomTimeRangeTypes.DATE_AND_TIME,
            },
            {
                text: i18n.t("filters.tabDate.dateAndTime.customTypes.date"),
                value: CustomTimeRangeTypes.DATE,
            },
        ],
        menuSinceDate: false,
        menuToDate: false,
        menuSinceTime: false,
        menuToTime: false,
        customDateTimeValidForm: false,
        timeRanges: TimeRanges,
        customDateAndTimeRangeType: CustomTimeRangeTypes,
        rules: {
            required: value => !!value || `${i18n.t('required')}`,
            twoDatesValidation: (firstDate, secondDate, selectedType) => {
                return (selectedType === CustomTimeRangeTypes.DATE)
                    && (!firstDate || !secondDate)
                    || (firstDate && secondDate && validateCustomDateRange(firstDate, secondDate))
                    || `${i18n.t('filters.tabDate.dateAndTime.dateError')}`
            },
            twoTimesValidation: (firstTime, secondTime, firstDate, secondDate, selectedType) => {
                return (selectedType === CustomTimeRangeTypes.DATE)
                    || (!firstDate || !secondDate || !firstTime || !secondTime)
                    || (firstTime && secondTime && selectedType === CustomTimeRangeTypes.TIME && firstDate.getTime() < secondDate.getTime())
                    || (firstDate && secondDate && firstTime && secondTime && validateCustomDateAndTimeRange(firstDate, secondDate, firstTime, secondTime))
                    || `${i18n.t("filters.tabDate.dateAndTime.timeError")}`
            }
        },
    }),
    props: {
        model: {
            type: Object,
            required: true,
        },
        showCustomOption: {
            type: Boolean,
            required: false,
            default: true
        },
        showCurrentHour: {
            type: Boolean,
            required: false,
            default: true
        },
        showOnlyLast123Days: {
            type: Boolean,
            required: false,
            default: false
        },
        showLast2Months: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted() {
        this.init();
        this.validateModel();
    },
    computed: {
        formattedSinceDate() {
            return this.formatDate(this.model.sinceDate);
        },
        formattedToDate() {
            return this.formatDate(this.model.toDate);
        }
    },
    methods: {
        init() {
            this.dateAndTimeRanges.cleanAndUpdate([]);
            if (!this.showOnlyLast123Days) {
                this.dateAndTimeRanges.push({
                    id: 't24Hs', text: i18n.t("filters.tabDate.dateAndTime.t24hs"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: 't48Hs', text: i18n.t("filters.tabDate.dateAndTime.t48hs"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: 't72Hs', text: i18n.t("filters.tabDate.dateAndTime.t72hs"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: 'divider', text: "", divider: true
                });
                if (this.showCurrentHour) {
                    this.dateAndTimeRanges.push({
                        id: TimeRanges.CURRENT_HOUR, text: i18n.t("filters.tabDate.dateAndTime.currentHour"), custom: false
                    });
                }
                this.dateAndTimeRanges.push({
                    id: TimeRanges.LAST_HOUR, text: i18n.t("filters.tabDate.dateAndTime.lastHour"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.LAST_SIX_HOUR, text: i18n.t("filters.tabDate.dateAndTime.lastSixHours"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.TODAY, text: i18n.t("filters.tabDate.dateAndTime.today"), custom: false,
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.YESTERDAY, text: i18n.t("filters.tabDate.dateAndTime.yesterday"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.CURRENT_WEEK, text: i18n.t("filters.tabDate.dateAndTime.currentWeek"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.LAST_WEEK, text: i18n.t("filters.tabDate.dateAndTime.lastWeek"), custom: false,
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.LAST_TWO_WEEKS, text: i18n.t("filters.tabDate.dateAndTime.lastTwoWeeks"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: TimeRanges.CURRENT_MONTH, text: i18n.t("filters.tabDate.dateAndTime.currentMonth"), custom: false
                });
                if (this.showLast2Months) {
                    this.dateAndTimeRanges.push({
                        id: TimeRanges.LAST_TWO_MONTHS, text: i18n.t("filters.tabDate.dateAndTime.lastTwoMonths"), custom: false
                    });
                }
                this.dateAndTimeRanges.push({
                    id: TimeRanges.CUSTOM, text: i18n.t("filters.tabDate.dateAndTime.custom"), custom: true
                });
            } else {
                this.dateAndTimeRanges.push({
                    id: 't24Hs', text: i18n.t("filters.tabDate.dateAndTime.t24hs"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: 't48Hs', text: i18n.t("filters.tabDate.dateAndTime.t48hs"), custom: false
                });
                this.dateAndTimeRanges.push({
                    id: 't72Hs', text: i18n.t("filters.tabDate.dateAndTime.t72hs"), custom: false
                });
            }
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        arrowIcon(show) {
            if (show) {
                return "arrow_drop_up";
            } else {
                return "arrow_drop_down";
            }
        },
        validateModel() {
            if (!this.model.selectedDateAndTimeCustomRangeType) {
                this.model.selectedDateAndTimeCustomRangeType = CustomTimeRangeTypes.DATE;
            }
        }
    },
    watch: {
        model: function () {
            this.validateModel();
        }
    }
};
