import { CustomTimeRangeTypes, SortOptions, TimeRanges } from '@colven/common-domain-lib/lib';

export default {
    generalData: {
        selectedThings: [],
        maxQuantity: 100,
    },
    dateData: {
        selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
        selectedDateAndTimeCustomRangeType: CustomTimeRangeTypes.DATE,
        sinceDate: null,
        sinceTime: null,
        toDate: null,
        toTime: null
    },
    orderData: {
        selectedSortOption: SortOptions.ALPHABETIC_ASC_NAME,
    },
    rangeData: {
        greaterThan: null,
        lessThan: null
    }
}