import ConfigurationsSectionComponent from "@/components/filter/sections/configurations/ConfigurationsSectionComponent.vue";
import DateSectionComponent from "@/components/filter/sections/date/DateSectionComponent.vue";
import GeneralSectionComponent from "@/components/filter/sections/general/GeneralSectionComponent.vue";
import OrderSectionComponent from "@/components/filter/sections/order/OrderSectionComponent.vue";
import RangeSectionComponent from "@/components/filter/sections/range/RangeSectionComponent.vue";
import Vue from 'vue';
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib';
import { validateCustomDateRange, validateCustomDateAndTimeRange, getIdThings } from '@/tools/functions';
/**
 * COMPONENTE DE FILTROS GENÉRICO
 * 
 * MODELO: objeto con la siguiente estructura:
 * 
 * filters: {
 *      generalData: {
 *          selectedThings: [{},{}...],  --> Están agrupados por TIPO de equipo
 *          serviceType: [{
 *              id: string
 *              name: string
 *              key: string
 *          }],
 *          maxQuantity: string
 *      },
 *      dateData: {
 *          selectedDateAndTimeRange: string
 *      },
 *      configurationsData: {
 *          selectedYieldUnit: string
 *      },
 *      orderData: {
 *          selectedSortOption: string
 *      },
 *      rangeData: {
 *          greaterThan: string
 *          lessThan: string
 *      }
 * }
 * PROPS:
 * ---showsFuelData: booleano que indica si mostrar o no el tab Configuración, en base a si el reporte muestra
 *  información de combustible o no. Opcional, puesto como TRUE por defecto.
 * ---dataForRangeFilter: objeto de datos para utilizar la sección de Rango. Ver comentario en RangeSectionComponent.
 * ---maxQuantityLimit: string que indica el límite de equipos máximos a mostrar en el reporte.
 * ---orderFilterWorstBestPropertyName: string con la property (nombre) por la cual se
 * ordenan los equipos mejores o peores primero.
 * EVENTOS:
 * ---close: evento que se lanza cuando se hace click para cerrar el selector de filtros.
 * ---saveChanges: evento que se lanza cuando se hace click en el botón "Guardar" para guardar la
 * configuración de filtros.
 * 
 * FILTROS:
 * Por defecto se muestran todos, excepto el de Rango, que para ello debe mandarse dataForRangeFilter.
 * El de Configuración puede no mostrarse si se manda la prop showsFuelData comentada anteriormente.
 */
export default {
    name: 'FilterComponent',
    model: {
        prop: "model",
        event: "change"
    },
    components: {
        GeneralSectionComponent,
        DateSectionComponent,
        ConfigurationsSectionComponent,
        OrderSectionComponent,
        RangeSectionComponent
    },
    data: () => ({
        filterShow: true,
        disableSaveFiltersButton: true,
    }),
    props: {
        model: {
            type: Object,
            required: true
        },
        dataForRangeFilter: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        maxQuantityLimit: {
            type: Number,
            required: true
        },
        orderFilterWorstBestPropertyName: {
            type: String,
            required: true
        },
        showDateSection: {
            type: Boolean,
            required: false,
            default: true
        },
        showCustomOption: {
            type: Boolean,
            required: false,
            default: true
        },
        showCurrentHour: {
            type: Boolean,
            required: false,
            default: true
        },
        //prop booleana para mostrar o no la sección de ordenamiento
        showOrderSection: {
            type: Boolean,
            required: false,
            default: true
        },
        //prop booleana para que en la sección general solo se muestre el selector de equipos y solo se elija uno
        selectThingOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        showOnlyLast123Days: {
            type: Boolean,
            required: false,
            default: false
        },
        showLast2Months: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    async mounted() {
        this.validateFilterData();
    },
    methods: {
        /**
         * Cierra el selector/modal de filtros
         */
        close() {
            this.$emit('close')
        },
        /**
         * Valida que estén los datos necesarios asegurando que el objeto 'filters' tenga
         * los datos que se requieren para generar el reporte
         */
        getReportClick() {
            this.$emit('saveChanges');
        },
        validateFilterData() {
            const that = this;
            Vue.nextTick(function () {
                const maxQuantity = Number(that.model.generalData.maxQuantity);
                const maxQLimit = that.maxQuantityLimit;
                const maxQValidation = (maxQuantity <= maxQLimit && maxQuantity > 0);

                let customRangeValidation = true
                if (!that.showCustomOption) {
                    delete that.model.dateData.sinceDate;
                    delete that.model.dateData.toDate;
                    delete that.model.dateData.sinceTime;
                    delete that.model.dateData.toTime;
                    delete that.model.dateData.selectedDateAndTimeCustomRangeType;
                } else {
                    // validaciones de rango de fechas personalizado
                    if (that.model.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
                        if (that.model.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE) {
                            customRangeValidation = that.model.dateData.sinceDate && that.model.dateData.toDate
                                ? validateCustomDateRange(that.model.dateData.sinceDate, that.model.dateData.toDate)
                                : false
                        } else if (that.model.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE_AND_TIME) {
                            customRangeValidation = that.model.dateData.sinceDate && that.model.dateData.toDate
                                && that.model.dateData.sinceTime && that.model.dateData.toTime
                                ? validateCustomDateAndTimeRange(that.model.dateData.sinceDate, that.model.dateData.toDate, that.model.dateData.sinceTime, that.model.dateData.toTime)
                                : false
                        }
                    }
                }
                if (!that.dataForRangeFilter.showRangeData) {
                    delete that.model.rangeData.lessThan;
                    delete that.model.rangeData.greaterThan
                }

                if (that.dataForRangeFilter.showRangeData) {
                    const lessThan = Number(that.model.rangeData.lessThan);
                    const greaterThan = Number(that.model.rangeData.greaterThan);
                    const minLimit = that.dataForRangeFilter.minLimit;
                    const maxLimit = that.dataForRangeFilter.maxLimit;

                    const lessThanValidation = (lessThan >= minLimit && lessThan <= maxLimit);
                    const greaterThanValidation = (greaterThan >= minLimit && greaterThan <= maxLimit);

                    that.disableSaveFiltersButton = !(maxQValidation && customRangeValidation && lessThanValidation && greaterThanValidation);
                } else if (that.selectThingOnly) {
                    //analizar las validaciones si esta prop se utiliza en otro reporte que no sea posiciones
                    const oneThingSelected = getIdThings(that.model.generalData.selectedThings).length > 0 ? true : false;
                    that.disableSaveFiltersButton = !(oneThingSelected && customRangeValidation);
                } else {
                    that.disableSaveFiltersButton = !(maxQValidation && customRangeValidation);
                }
            });
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        "model.rangeData.lessThan": function () {
            this.validateFilterData();
        },
        // eslint-disable-next-line no-unused-vars
        "model.rangeData.greaterThan": function () {
            this.validateFilterData();
        },
        // eslint-disable-next-line no-unused-vars
        "model.generalData.maxQuantity": function () {
            this.validateFilterData();
        },
        "model.dateData": {
            handler: function () {
                if (this.showDateSection) {
                    this.validateFilterData()
                }
            },
            deep: true
        },
        "model.generalData": {
            handler: function () {
                if (this.selectThingOnly) {
                    this.validateFilterData()
                }
            },
            deep: true
        }
    }

}