import i18n from "@/i18n";
/**
 * SECCIÓN DE RANGOS
 * 
 * MODELO: {
 *  greaterThan: string,
 *  lessThan: string
 * }
 * 
 * PROPS:
 * ---dataForRange: objecto que, de contener datos, debe tener la siguiente estructura:
 * {
 *      showRangeData: boolean,
 *      minLimit: string,
 *      maxLimit: string,
 *      propertyName: string
 * }
 * Donde:
 *  --showRangeData: indica si mostrar o no el filtro. Si es TRUE entonces se valida que TODAS LAS DEMÁS PROPERTIES estén dadas. Sino no se visualizará el filtro.
 *  --minLimit y maxLimit: son los límites del rango para la property dada según cada reporte.
 *  --propertyName: es el nombre de la property en cuestión.
 * Ejemplo:
 * {
        showRangeData: true,
        minLimit: "30",
        maxLimit: "100",
        propertyName: "PRUEBA"
    }
 */
export default {
    name: "RangeSectionComponent",
    model: {
        prop: "model",
        event: "change"
    },
    data: () => ({
        showRangeSection: false,
        showRangeData: false,
        minLimit: '',
        maxLimit: '',
        propertyName: '',
        rules: {
            limitMinReached: (value, limit) => !value || value >= Number(limit) || `${i18n.t('filters.error.minLimitReached', {limit:limit})}`,
            limitMaxReached: (value, limit) => !value || value <= Number(limit) || `${i18n.t('filters.error.maxLimitReached', {limit:limit})}`,
        }

    }),
    props: {
        model: {
            type: Object,
            required: true,
        },
        dataForRange: {
            type: Object,
            required: false,
            default: () => ({}),
        }
        
    },
    async mounted() {
        await this.verifyRangeData(this.dataForRange);
    },
    methods: {
        arrowIcon(show) {
        if (show) {
            return "arrow_drop_up";
        } else {
            return "arrow_drop_down";
        }
        },

        /**
         * Método que valida que, si se va a mostrar el filtro, TANTO LOS LIMITES COMO EL NOMBRE DE LA PROPIEDAD QUE CORRESPONDA
         * ESTÉN SETEADOS. De lo contrario el filtro de Rango no se mostrará.
         */
        verifyRangeData(data) {
            if(data.showRangeData) {
                this.showRangeData = true;
                if (
                    data.minLimit == null ||
                    data.minLimit === "" ||
                    data.maxLimit == null ||
                    data.maxLimit === "" ||
                    data.propertyName == null ||
                    data.propertyName === ""
                ) {
                    this.showRangeData = false;
                } else {
                    this.minLimit = data.minLimit;
                    this.maxLimit = data.maxLimit;
                    this.propertyName = data.propertyName;
                }
            }
        }
    },
    watch: {
        dataForRange: function(newDataForRange) {
            this.verifyRangeData(newDataForRange);
        }
    }
}